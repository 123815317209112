//@ts-check
import React, { useState, useEffect, useRef } from 'react';
import { workflowService } from '../Settings/CustomStatus/services/workflow.service';
import { thirdPartyIntegrationService } from './thirdPartyIntegration.service';
import { directoryService } from '../../components/Proof/directory.service';
import { TreeSelectView } from '../../components';
import { store, utilService } from '../../_helpers';
import { alertActions } from '../App';
import { MultiSelect } from '..';
import { useLiveUpdate } from '../App/LiveUpdate/LiveUpdateContext';
import { DataFetcher } from '../_shared/DataFetcher/DataFetcher';

const createAsOptions = [
    {
        label: 'New File Version',
        value: 0
    },
    {
        label: 'New File',
        value: 1
    }
];

export const LucidLinkSettingManage = ({ onClose }) => {
    const { changeTarget, setSettingsListeners } = useLiveUpdate();
    const [settings, setSettings] = useState({
        filespace: '',
        username: '',
        password: '',
        sourceDirectory: '',
        destinationDirectory: '',
        destinationDirectoryPath: '',
        createAs: 0,
        workflowId: '',
        workflowStatusId: ''
    });
    const [workflows, setWorkflows] = useState([]);
    const [workflowStatuses, setWorkflowStatuses] = useState([]);
    const [filteredTreeData, setFilteredTreeData] = useState([]);
    const [treeNodeSelectedValue, setTreeNodeSelectedValue] = useState({ label: '', value: '' });

    useEffect(() => {
        if (!settings.workflowId) {
            return;
        }

        let workflow = workflows.find((x) => x.id === settings.workflowId);
        updateWorkflowStatuses(workflow);
    }, [settings.workflowId]);

    const fetchData = async () => {
        await getWorkflowData(true);

        let directories = await directoryService.getAll({});
        setFilteredTreeData(directories[0].children);

        await getConfiguration();
    };

    const getConfiguration = async () => {
        let configuration = await thirdPartyIntegrationService.getLucidLinkConfiguration();
        setSettings((prev) => {
            return { ...prev, ...configuration };
        });

        if (configuration.destinationDirectoryPath) {
            let directoryPaths = configuration.destinationDirectoryPath.split('/');
            setTreeNodeSelectedValue({
                label: directoryPaths[directoryPaths.length - 1],
                value: configuration.destinationDirectory
            });
        }
    }

    const getWorkflowData = async (shouldSetSelectedWorkflow) => {
        var { workflows } = await workflowService.getAll(false, true);

        var rowData = [];
        workflows.forEach((element) => {
            var rowItem = {
                label: element.name,
                value: element.id,
                ...element
            };

            rowData.push(rowItem);

            // if (shouldSetSelectedWorkflow === true) {
            //     if (element.id === proofVersion.workflowId) {
            //         setSelectedWorkflow(rowItem);
            //     }
            // }
        });

        setWorkflows(rowData);
        return rowData;
    };

    const updateWorkflowStatuses = (workflow) => {
        var rowData = [];
        workflow.statuses.forEach((element) => {
            var rowItem = {
                label: element.name,
                value: element.id,
                ...element
            };

            rowData.push(rowItem);
        });

        setWorkflowStatuses(rowData);
    };

    const onWorkflowChange = (selectedOption) => {
        setSettings((prev) => ({
            ...prev,
            workflowId: selectedOption.id,
            workflowStatusId: ''
        }));
    };

    const updateSettings = (name, value) => {
        setSettings((prev) => ({
            ...prev,
            [name]: value
        }));
    };


    const searchTree = (tree, x) => {
        for (const node of tree) {
            if (node.key === x) {
                return node;
            }

            if (node.children && node.children.length > 0) {
                const result = searchTree(node.children, x);
                if (result) {
                    return result;
                }
            }
        }
        return null;
    }

    const onNodeSelect = (node) => {      
        node.operationStatus = 1;
        node.isApplyToChild = true;
        setTreeNodeSelectedValue(node);        
        var dir = searchTree(filteredTreeData, node.value);


        setSettings((prev) => {
            return { ...prev, 
                destinationDirectory: node.value, 
                destinationDirectoryPath : dir.path
            };
        });
    }

    const onSave = async () => {
        console.log(settings);
        await thirdPartyIntegrationService.saveLucidLinkConfiguration(settings);
        store.dispatch(alertActions.success('Saved !'));
        await getConfiguration();
    };

    return (
        <>
            <div className="settings-view__manage__container">
                <div className="m-2" style={{}}>
                    <div className="settings-view-modal">
                        <div style={{ minHeight: '80%', fontSize: '13px' }}>
                            <button
                                id="editor-close-btn"
                                type="button"
                                className="close modal-close"
                                title="Close Modal"
                                onClick={onClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h3 className="">LucidLink Settings</h3>
                            <hr></hr>
                            <DataFetcher height={'476px'} onFetchData={fetchData}>
                                <>
                                    <div style={{ maxWidth: 350 }} className={'form-group'}>
                                        <label htmlFor="filespace">Filespace</label>
                                        <input
                                            type="text"
                                            className="form-control mrnda-input-text"
                                            name="filespace"
                                            value={settings.filespace}
                                            onChange={(e) => updateSettings(e.target.name, e.target.value)}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div style={{ maxWidth: 350 }} className={'form-group'}>
                                        <label htmlFor="username">Username</label>
                                        <input
                                            type="text"
                                            className="form-control mrnda-input-text"
                                            name="username"
                                            value={settings.username}
                                            onChange={(e) => updateSettings(e.target.name, e.target.value)}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div style={{ maxWidth: 350 }} className={'form-group'}>
                                        <label htmlFor="password">Password</label>
                                        <input
                                            type="password"
                                            className="form-control mrnda-input-text"
                                            name="password"
                                            value={settings.password}
                                            onChange={(e) => updateSettings(e.target.name, e.target.value)}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div style={{ maxWidth: 350 }} className={'form-group'}>
                                        <label htmlFor="sourceDirectory">LucidLink Directory</label>
                                        <input
                                            type="text"
                                            className="form-control mrnda-input-text"
                                            name="sourceDirectory"
                                            value={settings.sourceDirectory}
                                            onChange={(e) => updateSettings(e.target.name, e.target.value)}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className={'form-group'}>
                                        <label>Miranda Directory</label>
                                        <p style={{paddingBottom:'4px'}}>Directory path: {settings.destinationDirectoryPath}</p>
                                        <TreeSelectView
                                            treeData={filteredTreeData}
                                            selectedValue={treeNodeSelectedValue}
                                            onNodeSelect={onNodeSelect}
                                            placeholder={'Select directory'}
                                        />
                                    </div>
                                    <div style={{ maxWidth: 350 }} className={'form-group'}>
                                        <label>Create As</label>
                                        <MultiSelect
                                            isMulti={false}
                                            options={createAsOptions}
                                            selectedOptions={[createAsOptions.find((x) => x.value === settings.createAs)]}
                                            onChange={(selectedOption) => {
                                                updateSettings('createAs', selectedOption.value);
                                            }}
                                            closeMenuOnSelect={true}
                                        />
                                    </div>
                                    <div style={{ maxWidth: 350 }} className={'form-group'}>
                                        <label>Select Workflow</label>
                                        <MultiSelect
                                            isMulti={false}
                                            options={workflows}
                                            selectedOptions={[workflows.find((x) => x.id === settings.workflowId)]}
                                            onChange={onWorkflowChange}
                                            closeMenuOnSelect={true}
                                        />
                                    </div>
                                    <div style={{ maxWidth: 350 }} className={'form-group'}>
                                        <label>Select Workflow Status</label>
                                        <MultiSelect
                                            isMulti={false}
                                            options={workflowStatuses}
                                            selectedOptions={[workflowStatuses.find((x) => x.id === settings.workflowStatusId)]}
                                            onChange={(selectedOption) => {
                                                updateSettings('workflowStatusId', selectedOption.id);
                                            }}
                                            closeMenuOnSelect={true}
                                        />
                                    </div>
                                    <div className="modal-footer justify-content-start pl-0">
                                        <button className="btn mrnda-btn" onClick={onSave}>
                                            Save
                                        </button>
                                        <button className="mrnda-btn--secondary" onClick={onClose}>
                                            Close
                                        </button>
                                    </div>
                                </>
                            </DataFetcher>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
